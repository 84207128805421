<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Send Playbook</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <Form @submit="handleSendPlaybook" v-slot="{ errors }">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Select Playbook</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.playbook }">
                                    <Field autocomplete="off" name="playbook" v-model="form.playbook_id" rules="required">
                                        <multiselect
                                            v-model="form.playbook_id"
                                            label="title"
                                            value-prop="id"
                                            :searchable="true"
                                            :options="playbooks"
                                            placeholder="Select playbooks"
                                            mode="tags"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="playbook" class="text-danger" />
                            </div>
                        </div>
                        <div v-show="form.playbook_id.length">
                            <h3 class="sub_header">Notification Method</h3>
                            <div class="edit_section">
                                <sending-method v-model="form.sending_method" />
                            </div>
                            <schedule-component :schedule-data="form" title="playbook" :close-modal="closeModal" />
                        </div>
                        <div class="action_wpr mt-5">
                            <button :disabled="playbookLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="playbookLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="playbookLoader"></i>{{ playbookLoader ? ' Sending' : 'Send' }}</button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex';

    const ScheduleComponent = defineAsyncComponent(() => import('@/components/Schedule'))

    export default {
        name: 'Send Playbook',

        data () {
            return {
                form: {
                    contacts: '',
                    playbook_id: [],
                    action: '/contacts/send-playbook',
                    type: 1,
                    sending_method: 1,
                },
            };
        },

        props: {
            modelValue: Boolean,
            contacts: [Object, Array],
        },

        emits: ['update:modelValue'],

        watch: {
            'form.sending_method' (sendingMethod) {
                const vm = this;

                vm.form.type = sendingMethod;
            },

            modelValue (value) {
                const vm = this;

                if (value == true) {
                    vm.form.contacts = vm.contacts;
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }

                if (vm.playbooks.length == 0) {
                    vm.getAllPlaybooks();
                }
            },
        },

        components: {
            Form,
            Field,
            ErrorMessage,
            ScheduleComponent,
        },

        computed: {
            ...mapState({
                playbooks: state => state.playbookModule.allPlaybooks,
                playbookLoader: state => state.playbookModule.playbookSendLoader,
            }),
        },

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                getAllPlaybooks: 'playbookModule/getAllPlaybooks',
                sendPlaybook: 'playbookModule/sendPlaybook',
            }),

            closeModal () {
                const vm = this;

                vm.resetForm();
                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                              contacts: '',
                              playbook_id: [],
                              action: '/contacts/send-playbook',
                              type: 1,
                              sending_method: 1,
                          };
            },

            handleSendPlaybook (params, { setFieldError }) {
                const vm = this;

                vm.form.setFieldError = setFieldError;
                vm.form.closeModal = vm.closeModal;

                vm.sendPlaybook(vm.form);
            },
        },
    }
</script>
